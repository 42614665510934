html {
  width: 100vw;
  height: 100vh;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  background-color: #eee4ce;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  margin: 10px;
  padding: 10px;
  background-color: cadetblue;
  color: whitesmoke;
  font-size: large;
  border-radius: 25px;
  border-style: none;
}

a {
  font-size: small;
}

ul {
  padding-left: 0;
}

li {
  list-style: none;
}

.error {
  color: red;
}
